<mat-sidenav-container class="example-container" [hasBackdrop]="true">
  <mat-sidenav
    [opened]="isNotificationPanelOpen"
    mode="over"
    position="end"
    fixedInViewport="true"
    class="md-sidebar"
    [disableClose]="true"
  >
    @if (isNotificationPanelOpen) {
      <app-notification-panel [unreadNotificationCount]="unreadNotificationCount" (refreshUnreadCount)="getUnreadNotificationCount()" (closeSideNav)="toggleNotificationPanel(false)"></app-notification-panel>
    }
  </mat-sidenav>
</mat-sidenav-container>

<div class="header-element-container">
  <div class="position-relative">
    <img
      [src]="constants.staticImages.icons.bellIcon"
      alt=""
      class="notification"
      (click)="toggleNotificationPanel(true)"
    />
    @if (unreadNotificationCount) {
    <div class="unread-badge">
        {{ unreadNotificationCount }}
      </div>
    }
  </div>

  @if (!showPageLoader) {
    @if (currentUser?.profilePicturefullurl) {
      <img
        [src]="currentUser?.profilePicturefullurl ?? ''"
        [appImageLoader]="constants.staticImages.loaders.imageLoader"
        [appImagePlaceholder]="constants.staticImages.images.profileImgPlaceholder"
        alt="Profile image"
        class="profile-img pointer"
        [routerLink]="path.profile.root"
        [queryParams]="{ activeTab: 'Account Profile' }"
      />
    } @else {
      <div
        class="placeholder-name"
        [routerLink]="path.profile.root"
        [queryParams]="{ activeTab: 'Account Profile' }"
      >
        {{ setInitials(currentUser?.firstName, currentUser?.lastName) | uppercase }}
      </div>
    }
  }
  <img
    [src]="constants.staticImages.icons.logOut"
    alt=""
    class="logout"
    (click)="openLogoutConfirmationDialog()"
  />
</div>

<ng-template #logoutDialog>
  <h2 mat-dialog-title>Logout</h2>
  <mat-dialog-content>
    <p>Are you sure you want to logout?</p>
  </mat-dialog-content>
  <mat-dialog-actions [align]="'end'">
    <button mat-raised-button color="accent" class="mat-accent-btn back-btn" (click)="closeDialog()">
      Cancel
    </button>
    <button mat-raised-button color="warn" class="mat-warn-btn" (click)="confirmLogout()">
      Logout
    </button>
  </mat-dialog-actions>
</ng-template>
