import { HttpClient } from '@angular/common/http';
import { Injectable, Injector } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';
import { LoginResponse } from 'src/app/auth/models';
import { API_URL } from '../constants/api-url.constants';
import { LocalStorageService, StorageItem } from './local-storage.service';
import { AuthService } from 'src/app/auth/services';

@Injectable({ providedIn: 'root' })
export class RefreshTokenService {
  private refreshTokenTimeout!: NodeJS.Timeout;
   private authService!: AuthService;

  constructor(
    private readonly httpClient: HttpClient,
    private readonly localStorageService: LocalStorageService,
    private readonly injector: Injector,
  ) {}

  startRefreshTokenTimer(expireTime: number) {
    // parse json object from base64 encoded jwt token
    const jwtToken = expireTime;

    const expires = jwtToken * 500;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), expires);
  }

  stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private getAuthService(): AuthService {
    if (!this.authService) {
      this.authService = this.injector.get(AuthService);
    }
    return this.authService;
  }

  refreshToken(): Observable<LoginResponse | null> {
    const refreshToken = this.localStorageService.getItem(StorageItem.RefreshToken) as string;
    if (!refreshToken) {
      this.stopRefreshTokenTimer(); 
      this.getAuthService().logOut();
      return of(null);
    }
    return this.httpClient
      .post<LoginResponse>(
        `${API_URL.account.tokeAuth}/${API_URL.account.refreshAccessToken}?refreshToken=${refreshToken}`,
        {}
      )
      .pipe(
        map((response) => {
          this.localStorageService.setItem(StorageItem.AuthToken, response.result.accessToken);
          this.startRefreshTokenTimer(response.result.expireInSeconds);
          return response;
        }),
        catchError(() => {
          this.getAuthService().logOut(); 
          return of(null);
        })
      );
  }

  storeAuthTokens(loginResponse: LoginResponse) {
    this.localStorageService.setItem(StorageItem.AuthToken, loginResponse.result.accessToken);
    this.localStorageService.setItem(StorageItem.RefreshToken, loginResponse.result.refreshToken);
  }

  isTokenExpired(token: string): boolean {
    try {
      const payload = JSON.parse(atob(token.split('.')[1])); 
      const expiryTime = payload.exp;
      return expiryTime < Date.now();
    } catch (error) {
      return true;
    }
  }
}
