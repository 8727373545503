import { Injectable } from '@angular/core';
import { Constants } from '../constants';

@Injectable({
  providedIn: 'root'
})
export class NotificationSoundService {
  private audioElement: HTMLAudioElement;

  constructor() {
    this.audioElement = new Audio(Constants.sounds.newChatMessage);
    this.audioElement.volume = 1.0;
    this.audioElement.muted = false;
  }

  playSound(): void {
    this.audioElement.play().catch(error => console.error('Error playing sound:', error));
  }
}
