import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { CBResponse } from 'src/app/shared/models';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { NotificationDetails } from '../../models';
import { takeUntil } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { All } from 'src/app/pages/settings/pages/plan/models';

@Component({
  selector: 'app-notification-panel',
  templateUrl: './notification-panel.component.html',
  styleUrl: './notification-panel.component.scss'
})
export class NotificationPanelComponent extends BaseComponent implements OnInit {
  @Input() unreadNotificationCount!: number;

  notificationDetails!: Array<NotificationDetails>;
  pageSize = this.paginationConfig.itemsPerPage;
  currentPage = this.paginationConfig.pageNumber;
  hasMoreNotifications = false;
  isLoading = false;
  all = All;

  @Output() closeSideNav = new EventEmitter<void>();
  @Output() refreshUnreadCount = new EventEmitter<void>();

  constructor(private readonly notificationService: NotificationService, private readonly cdr: ChangeDetectorRef) {
    super();
  }

  ngOnInit(): void {
    this.getAllNotifications(true);
  }

  onScroll(): void {
    if (this.isLoading || !this.hasMoreNotifications) return;
    this.getAllNotifications();
  }

  getAllNotifications(resetNotification = false): void {
    if (resetNotification) {
      this.currentPage = 1;
      this.notificationDetails = [];
      this.hasMoreNotifications = true;
    }

    resetNotification ? (this.showPageLoader = true) : (this.isLoading = true);
    this.notificationService
      .getListWithPagination<CBResponse<NotificationDetails>>(
        this.currentPage,
        this.pageSize,
        `${API_URL.notifications.getAllNotificationsOfUser}`
      )
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: CBResponse<NotificationDetails>) => {
          this.notificationDetails = [...this.notificationDetails, ...res.result.items];
          if (res.result.items.length === this.pageSize) {
            this.currentPage++;
          } else {
            this.hasMoreNotifications = false;
          }
  
          resetNotification ? (this.showPageLoader = false) : (this.isLoading = false);
          this.cdr.detectChanges();
        },
        error: () => {
          resetNotification ? (this.showPageLoader = false) : (this.isLoading = false);
          this.cdr.detectChanges();
        }
      });
  }

  readNotification(notificationId: number, isAllRead: boolean): void {
    if(!this.unreadNotificationCount) return;
    this.notificationService
      .add({}, `${API_URL.notifications.readNotifications}?id=${notificationId}&isReadAll=${isAllRead}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getAllNotifications(true);
          this.refreshUnreadCount.emit();
        }
      });
  }

  deleteNotification(notificationId: number, isAllDelete: boolean): void {
    if(!this.notificationDetails.length) return;
    this.notificationService
      .deleteNotification(notificationId, isAllDelete,`${API_URL.crud.delete}`)
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: () => {
          this.getAllNotifications(true);
          this.refreshUnreadCount.emit();
        }
      });
  }

  onCloseSideNav(): void {
    this.closeSideNav.emit();
  }
}
