import { Validators } from '@angular/forms';
import { ActivatedRouteSnapshot } from '@angular/router';
import moment from 'moment';

export class CommonUtils {
  static getFullName(firstName: string | undefined, lastName: string | undefined): string {
    return `${firstName || ''} ${lastName || ''}`;
  }

  static getInitials(firstName: string | undefined, lastName: string | undefined): string {
    return `${firstName?.charAt(0) || ''}${lastName?.charAt(0) || ''}`;
  }

  static getInitialsUsingFullName(name: string | undefined): string {
    if (name?.includes(' ')) {
      return `${name?.split(' ')[0].charAt(0) || ''}${name?.split(' ')[1].charAt(0) || ''}`;
    } else {
      return `${name?.charAt(0)}`;
    }
  }

  static getPageTitle(routeSnapshot: ActivatedRouteSnapshot) {
    let title: string = routeSnapshot.data && routeSnapshot.data['pageTitle'] ? routeSnapshot.data['pageTitle'] : 'OMS';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }

  static formatNames(str: string, limit: number = 3): string {
    if (str.includes(',')) {
      const names = str.split(',');
      const displayedNames = names.slice(0, limit).join(', ');
      const remainingCount = names.length - limit;

      return remainingCount > 0 ? `${displayedNames} +${remainingCount}` : displayedNames;
    }
    return str;
  }

  static cleanObjectByRemovingKeysWithoutValue(obj: any): any {
    if (obj && typeof obj === 'object') {
      Object.keys(obj).forEach(key => {
        if (!obj[key] || obj[key] === '') {
          delete obj[key];
        }
      });
    }
    return obj;
  }

  static calculateTimeDifference(startDate: Date, endDate: Date): number {
    const differenceInMs = endDate.getTime() - startDate.getTime();
    return Math.floor(differenceInMs / (1000 * 60));
  }

  static b64toBlob = (b64Data: any, contentType='', fileName: string) => {
    const byteCharacters = atob(b64Data);
    const byteArrays = [];
    const sliceSize = 512;
  
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
  
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
  
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }
      
    const blob = new Blob(byteArrays, {type: contentType});
    this.downloadFromBlob(blob, blob.type, fileName)
  }

  static downloadFromBlob(response: Blob, type: string, fileName: string): void {
    const blob = new Blob([response], { type: type });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement('a');
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  }

  static getAgeFromDob(dob: string): number {
    return moment().diff(moment(dob, moment.ISO_8601), 'years');
  }
}
