import { versions } from './versions';

export const environment = {
  versions,
  production: false,
  hostUrl: '',
  apiUrl: 'https://dev-api.octopusmusicschool.com/api',
  frontendUrl: 'https://dev.octopusmusicschool.com/',
  signalRUrl: 'https://dev-api.octopusmusicschool.com/signalr-octopus-chat',
  persistUserSession: true,
  sentryKey: 'https://86acecc08fb74322b197c4225654ff74@sentry.thesunflowerlab.com/49',
  firebase: {
    apiKey: "AIzaSyAzHJnElwAFELX9nDmnQY0Fyaz3AKvZR7E",
    authDomain: "octopus-dev-a7cd5.firebaseapp.com",
    projectId: "octopus-dev-a7cd5",
    storageBucket: "octopus-dev-a7cd5.firebasestorage.app",
    messagingSenderId: "236426501295",
    appId: "1:236426501295:web:bc68d6376a3ea02e6a885a"
  },
  swUpdate: false,
  swUpdateFooter: false,
  encryptionService: {
    enable: false,
    secretKey: 'BfBRoYxsRKGnx3DlPgnsmei7qVHTD62o',
    secretIV: 'cGsgbxxqbcYN6HZG'
  },
  compressAfterFileSize: 2
};
