import { Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { Subject, take } from 'rxjs';
import { ChatService } from 'src/app/pages/messages/services/chat.service';
import { API_URL } from '../constants/api-url.constants';
import { AuthService } from 'src/app/auth/services';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private baseUrl = environment.signalRUrl;
  private connectionRegistered = false;

  private messageSource = new Subject<string>();
  currentMessage = this.messageSource.asObservable();

  private chatHistorySource = new Subject<string>();
  chatHistory = this.chatHistorySource.asObservable();

  private unreadMessageCount = new Subject<number>();
  unreadMessageCount$ = this.unreadMessageCount.asObservable();

  constructor(private readonly chatService: ChatService, private readonly authService: AuthService) {}

  public startConnection(): void {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      return;
    }

    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(this.baseUrl)
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();

    this.hubConnection
      .start()
      .then(() => {
        const connectionId = this.hubConnection.connectionId;
        const url = `${API_URL.octopusChatAppServices.onConnected}?connectionId=${connectionId}`;

        this.authService.isLoggedIn$.pipe(take(1)).subscribe(isLoggedIn => {
          if (isLoggedIn && !this.connectionRegistered) {
            this.chatService.add(null, url).subscribe({
              next: () => {
                this.connectionRegistered = true;
              },
              error: err => {
                console.error('Error adding connection info to API:', err);
              }
            });
          } else if (!isLoggedIn) {
            this.hubConnection.stop();
          }
        });
      })
      .catch(err => {
        console.error('Error connecting SignalR: ', err);
      });

    this.listenForMessages();
    this.listenForGroupMessages();
    this.updateUnreadCount();
    this.updateChatHistory();
  }

  private listenForMessages(): void {
    this.hubConnection.on('ReceiveMessage', (message: any) => {
      this.messageSource.next(message);
    });
  }

  private listenForGroupMessages(): void {
    this.hubConnection.on('ReceiveGroupMessage', (message: any) => {
      this.messageSource.next(message);
    })
  }

  private updateChatHistory(): void {
    this.hubConnection.on('UpdateChatHistory', (message: any) => {
      this.chatHistorySource.next(message);
    })
  }

  private updateUnreadCount(): void {
    this.hubConnection.on('UpdateUnreadCount', (res) => {
      this.unreadMessageCount.next(res);
    })
  }

  public sendMessage(message: string): void {
    this.hubConnection.invoke('SendMessage', message).catch(err => console.error(err));
  }

  public stopConnection(): void {
    if (this.hubConnection && this.hubConnection.state === signalR.HubConnectionState.Connected) {
      this.hubConnection.stop().then(() => {
        this.chatService.add(null, API_URL.octopusChatAppServices.disConnected).subscribe();
      });
    }
  }
}
