import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output
} from '@angular/core';
import { NavigationEnd, Router, Scroll } from '@angular/router';
import { ToggleDirections } from '../header/header.model';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { SignalRService } from 'src/app/shared/services/signalr.service';
import { ChatService } from 'src/app/pages/messages/services';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { takeUntil } from 'rxjs';
import { CBGetResponse } from 'src/app/shared/models';
import { NotificationSoundService } from 'src/app/shared/services';
import { ROUTER_PATHS } from 'src/app/shared/constants';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent extends BaseComponent implements OnInit {
  @Input() currentActiveParentRoute!: string;
  @Input() isMobileDevice!: boolean;
  @Input() isExpanded!: boolean;
  @Input() menuItems: any;

  toggleDirections = ToggleDirections.LEFT;
  unreadMessageCount = 0;
  isTabActive = true;

  @Output() setIsExpanded = new EventEmitter<boolean>();

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly signalRService: SignalRService,
    private readonly chatService: ChatService,
    private readonly notificationSoundService: NotificationSoundService,
  ) {
    super();
    this.setActiveRoute();
    this.getUnreadMessageCount();
  }

  ngOnInit(): void {
    this.isExpanded = !this.isMobileDevice;
    this.setIsExpanded.emit(this.isExpanded);
    window.addEventListener('visibilitychange', () => {

      this.isTabActive = document.visibilityState === 'visible';
    });
  }

  setActiveRoute(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.currentActiveParentRoute = event.url.split('/')[1];
        this.playMessageSound();
      } else if (event instanceof Scroll) {
        this.currentActiveParentRoute = event.routerEvent.url.split('/')[1];
      }
      this.cdr.detectChanges();
    });
  }

  getUnreadMessageCount(): void {
    this.signalRService.unreadMessageCount$.subscribe((count) => {
      this.unreadMessageCount = count;
      this.cdr.detectChanges();
    });

    this.chatService.getList<CBGetResponse<number>>(API_URL.octopusChatAppServices.getTotalCountForUnreadMessage)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: (res) => {
        this.unreadMessageCount = res.result;
        this.cdr.detectChanges();
      }
    });
  }

  playMessageSound(): void {
    this.signalRService.chatHistory.subscribe((chat) => {
      if ((chat && this.currentActiveParentRoute !== ROUTER_PATHS.message) || (!this.isTabActive && chat)) {
        this.notificationSoundService.playSound();
        this.cdr.detectChanges();
      }
    });
  }

  handleSidebarToggle() {
    this.setIsExpanded.emit(!this.isExpanded);
    this.isExpanded = !this.isExpanded;
  }

  getIsParentActive(menuItem: any, currentActiveParentRoute: string): boolean {
    return menuItem?.moduleKeys.includes(currentActiveParentRoute);
  }
}
