import { Directive, Output, EventEmitter, HostListener, HostBinding } from '@angular/core';
import { Constants } from '../constants';

@Directive({
  selector: '[appDnd]'
})
export class DragAndDropFileDirective {
  @Output() fileDropped = new EventEmitter<File>();
  @Output() invalidFile = new EventEmitter<string>();
  allowedExtensions = ['pdf'];
  maxFileSize = Constants.maxFileSizes.MAX_FILE_SIZE_2MB;

  @HostBinding('class.drag-over') private isDragOver: boolean = false;

  @HostListener('dragover', ['$event']) onDragOver(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = true;
  }

  @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;
  }

  @HostListener('drop', ['$event']) public ondrop(event: DragEvent) {
    event.preventDefault();
    event.stopPropagation();
    this.isDragOver = false;

    const files = event.dataTransfer?.files;
    if (!files || files.length === 0) {
      return;
    }

    if (files.length > 1) {
      this.invalidFile.emit(Constants.errorMessages.fileUploadLimt);
      return;
    }

    const file = files[0];
    const fileExtension = file.name.split('.').pop()?.toLowerCase();

    if (!fileExtension || !this.allowedExtensions.includes(fileExtension)) {
      this.invalidFile.emit(Constants.errorMessages.fileUploadPdfOnly);
      return;
    }

    if (file.size > this.maxFileSize) {
      this.invalidFile.emit(Constants.errorMessages.fileSizeExceeds.replace('{item}', 'File').replace('{size}', '2MB'));
      return;
    }

    this.fileDropped.emit(file);
  }
}
