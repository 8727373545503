import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, OnInit } from '@angular/core';
import { takeUntil } from 'rxjs';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { APP_CONFIG, MenuConfig } from 'src/app/shared/constants';
import { MenuConfigService } from 'src/app/shared/services';
import { StorageItem } from 'src/app/shared/services/local-storage.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { SignalRService } from 'src/app/shared/services/signalr.service';

@Component({
  selector: 'app-authorized-layout',
  templateUrl: './authorized-layout.component.html',
  styleUrls: ['./authorized-layout.component.scss', '../../shared/components/splash-screen/splash-screen.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class AuthorizedLayoutComponent extends BaseComponent implements OnInit {
  appConfig = APP_CONFIG;
  isMobileDevice!: boolean;
  isExpanded!: boolean;
  menuItems: any;

  constructor(
    private readonly authService: AuthService,
    private readonly menuConfigService: MenuConfigService,
    private readonly cdr: ChangeDetectorRef,
    private readonly signalRService: SignalRService,
    private readonly messagingService: MessagingService
  ) {
    super();
  }

  getIsMobile(): boolean {
    const w = document.documentElement.clientWidth;
    const breakpoint = 1000;
    return w > breakpoint;
  }

  ngOnInit(): void {
    this.isMobileDevice = !this.getIsMobile();
    this.getCurrentUser();
    this.signalRService.startConnection();
  }

  @HostListener('window:resize', ['$event']) onResize() {
    this.isMobileDevice = !this.getIsMobile();
  }

  getCurrentUser(): void {
    this.showPageLoader = true;
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: res => {
          if (res?.userRole) {
            this.menuConfigService.modifyMenuConfig(MenuConfig, res?.userRole);
            this.menuConfigAccordingToPermission();
            this.currentUser = res;
            this.performFirebaseActions();
            this.showPageLoader = false;
            this.cdr.detectChanges();
          }
        }
      });
  }

  performFirebaseActions(): void {
    if(!localStorage.getItem(StorageItem.FirebaseRegistrationToken)) {
      this.messagingService.getFirebaseToken();
      this.messagingService.receiveMessage();
      this.messagingService.currentMessage.subscribe(res => {
        if (res) {
          console.log('message recieved', res);
        }
      });
    }
  }

  menuConfigAccordingToPermission() {
    this.menuItems = this.menuConfigService.getMenuConfig();
  }
}
