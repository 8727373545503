<div class="o-sidebar-wrapper">
  <div class="o-sidebar-header">
    <div class="title">
        Notifications <div class="unread-badge" *ngIf="unreadNotificationCount">{{ unreadNotificationCount }}</div>
    </div>
    <div class="action-btn-wrapper">
      <button mat-raised-button color="accent" class="mat-accent-btn back-btn" type="button" (click)="onCloseSideNav()">
        Close
      </button>
    </div>
  </div>
  <div class="divider"></div>
  <div class="o-sidebar-body">
    <ng-container [ngTemplateOutlet]="showPageLoader ? showLoader : isDataPresent"></ng-container>
  </div>
</div>

<ng-template #isDataPresent>
    <ng-container [ngTemplateOutlet]="!notificationDetails.length ? noDataFound : notificationTemp"></ng-container>
</ng-template>

<ng-template #notificationTemp>
    <div class="notification-detail-wrapper">
        <div class="notification-actions">
            <div class="read-all" (click)="readNotification(all.ALL, true)"><mat-icon>done_all</mat-icon> Read All</div>
            <div class="read-all ms-3" (click)="deleteNotification(all.ALL, true)"><mat-icon>delete_sweep</mat-icon> Delete All</div>
        </div>
        <div class="notification" 
        infiniteScroll 
        [infiniteScrollDistance]="constants.infiniteScrollConfig.infiniteScrollDistance"
        [infiniteScrollUpDistance]="constants.infiniteScrollConfig.infiniteScrollUpDistance"
        [infiniteScrollThrottle]="constants.infiniteScrollConfig.infiniteScrollThrottle" 
        [scrollWindow]="false"
        (scrolled)="onScroll()">
        <div class="dotted-divider mt-0"></div>
            @for (notificationDetail of notificationDetails; track $index) {
                <div class="notification-detail" [ngClass]="{ 'unread-notification': !notificationDetail?.isRead }">
                    <div class="notification-detail-content" (click)="readNotification(notificationDetail.id, false)">
                        <div class="notification-title">
                            {{  notificationDetail?.title }}
                        </div>
                        <div class="notification-message">
                            {{  notificationDetail?.message }}
                        </div>
                    </div>
                    <div class="delete-notification" (click)="deleteNotification(notificationDetail.id, false)">
                        <mat-icon>close</mat-icon>
                    </div>
                </div>
                <div *ngIf="!$last" class="dotted-divider"></div>
            }

            <div class="loading-container" *ngIf="isLoading">
                <ng-container [ngTemplateOutlet]="showLoader"></ng-container>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #showLoader>
    <div class="page-loader-wrapper">
      <app-content-loader></app-content-loader>
    </div>
</ng-template>

<ng-template #noDataFound>
    <div class="no-data-found-wrapper">
      <h3>No new notifications</h3>
    </div>
</ng-template>
