<div class="sidebar" [ngClass]="{ expanded: isExpanded }">
  <div class="header">
    <h1 class="title d-flex">
      @if (isExpanded) {
        <img
          [src]="constants.applicationLogoUrl"
          class="img-fluid"
          [routerLink]="[path.dashboard.root]"
          alt="Company Logo" />
      } @else {
        <div></div>
      }
    </h1>
    <div class="toggle" (click)="handleSidebarToggle()">
      <img
        [src]="isExpanded ? constants.staticImages.icons.sidebarClose : constants.staticImages.icons.sidebarClose"
        class="sidebar-on-img" />
    </div>
  </div>
  <div class="content main">
    <ng-container *ngFor="let menu of menuItems">
      <ng-container *ngIf="menu.subMenuOptions?.length">
        <div class="link" [ngClass]="{ 'with-children': menu.subMenuOptions.length }">
          <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
          <ng-container *ngFor="let child of menu.subMenuOptions">
            @if (menu.isOpen && isExpanded) {
              <div class="children">
                <div class="link">
                  <ng-container
                    [ngTemplateOutlet]="menuTemplate"
                    [ngTemplateOutletContext]="{ menu: child }"></ng-container>
                </div>
              </div>
            }
          </ng-container>
        </div>
      </ng-container>
      <ng-container *ngIf="!menu.subMenuOptions?.length">
        <div class="link">
          <ng-container [ngTemplateOutlet]="menuTemplate" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
        </div>
      </ng-container>
    </ng-container>
  </div>
  <div class="footer"></div>
</div>

<ng-template #menuTemplate let-menu="menu">
  <ng-container *ngIf="!menu.subMenuOptions?.length">
    <div class="content" [routerLink]="menu.routerLink" [routerLinkActive]="'active'" [title]="menu.title">
      <ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
    </div>
  </ng-container>
  <ng-container *ngIf="menu.subMenuOptions?.length">
    <div
      class="content"
      [routerLink]="menu.routerLink"
      [ngClass]="{ active: getIsParentActive(menu, currentActiveParentRoute) && !isExpanded }"
      [title]="menu.title">
      <ng-container [ngTemplateOutlet]="menuItemTemplate" [ngTemplateOutletContext]="{ menu: menu }"></ng-container>
    </div>
  </ng-container>
</ng-template>

<ng-template #menuItemTemplate let-menu="menu">
  <div class="menu-wrapper" (click)="menu.isOpen = !menu.isOpen">
    <div class="icon" [class]="menu.icon">
      <img [src]="menu.icon" alt="" />
    </div>
    <div [ngClass]="{ 'title-wrapper': true, 'd-none': !isExpanded }">
      <div class="title">{{ menu.title }}</div>
      @if (menu.id === path.message && unreadMessageCount) {
        <span class="unread-badge">{{ unreadMessageCount }}</span>
      }
      @if (menu?.subMenuOptions?.length && isExpanded) {
        <div class="d-flex">
          @if (menu.isOpen) {
            <mat-icon>keyboard_arrow_down</mat-icon>
          } @else {
            <mat-icon>keyboard_arrow_right</mat-icon>
          }
        </div>
      }
    </div>
  </div>
</ng-template>
