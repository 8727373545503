
import { Injectable } from '@angular/core';
import { BehaviorSubject, takeUntil } from 'rxjs';
import { API_URL } from '../constants/api-url.constants';
import { FirebaseSubscriptionModel } from '../models/firebase-subscription.model';
import { LocalStorageService, StorageItem } from './local-storage.service';
import { AngularFireMessaging } from '@angular/fire/compat/messaging';
import { AuthService } from 'src/app/auth/services';
import { Account } from 'src/app/auth/models/user.model';
import { BaseComponent } from '../components/base-component/base.component';
import { FirebaseService } from './firebase.service';

@Injectable({
  providedIn: 'root'
})
export class MessagingService extends BaseComponent {
  currentMessage: BehaviorSubject<any> = new BehaviorSubject<any>(null);
  notificationPermission: BehaviorSubject<NotificationPermission> = new BehaviorSubject<NotificationPermission>(Notification.permission);
  private previousPermission: NotificationPermission = Notification.permission;

  constructor(
    private readonly angularFireMessaging: AngularFireMessaging,
    private readonly authService: AuthService,
    private readonly firebaseService: FirebaseService,
    private readonly localStorageService: LocalStorageService,
  ) {
    super();
    this.monitorNotificationPermission();
    this.angularFireMessaging.messages.subscribe((_messaging: any) => {
      _messaging.onMessage = (payload: any) => {
        console.log({ payload });
        this.getMessage(payload);
      };
    });
  }

  requestPermission(): void {
    this.angularFireMessaging.requestToken.subscribe({
      next: (token: string | null) => {
      },
      error: (err: any) => {
        console.error('Unable to get permission to notify.', err);
      }
    });
  }

  getFirebaseToken(): void {
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Account | null) => {
        this.angularFireMessaging.getToken.pipe(takeUntil(this.destroy$)).subscribe({
          next: (token: string | null) => {
            if (token) {
              localStorage.setItem(StorageItem.FirebaseRegistrationToken, token);
              
              let deviceUUID = localStorage.getItem(StorageItem.DeviceUUID);

              if(!deviceUUID) {
                deviceUUID = self.crypto.randomUUID();
                localStorage.setItem(StorageItem.DeviceUUID, deviceUUID);
              }

              const registerParams: FirebaseSubscriptionModel = {
                userId: res?.userId,
                token,
                deviceId: deviceUUID
              };
              this.registerToken(registerParams);
            }
          },
          error: (err: any) => {
            console.error('Unable to get permission to notify.', err);
          }
        });
      });
  }

  getMessage(payload: any): void {
    const notificationOptions = {
      title: payload.notification.title,
      body: payload.notification.body
    };
    navigator.serviceWorker.getRegistration('/firebase-cloud-messaging-push-scope').then((registration: any) => {
      console.log(registration, payload, notificationOptions);
      registration.showNotification(payload?.notification?.body, notificationOptions);
    });
    this.currentMessage.next(payload);
  }

  private monitorNotificationPermission(): void {
    if(!this.authService.isLoggedIn) {
      return;
    }
    this.authService
      .getCurrentUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: Account | null) => {
        setInterval(() => {
          if (Notification.permission !== this.previousPermission) {
            this.previousPermission = Notification.permission;
            this.notificationPermission.next(Notification.permission);

            const deviceUUID = localStorage.getItem(StorageItem.DeviceUUID)!;

            const registerParams: FirebaseSubscriptionModel = {
              userId: res?.userId,
              deviceId: deviceUUID
            };
    
            if (Notification.permission === "granted") {
              this.getFirebaseToken();
            } else if (Notification.permission === "denied") {
              this.unRegisterToken(registerParams);
            }
          }
        }, 100);
      });
  }

  receiveMessage(): void {
    this.angularFireMessaging.messages.subscribe(message => {
      console.log('Message received:', message);
    });
  }

  registerToken(registrationParams: FirebaseSubscriptionModel): void {
    this.firebaseService.add(registrationParams, API_URL.firebase.registerUserToken)
    .pipe(takeUntil(this.destroy$))
    .subscribe();
  }

  unRegisterToken(registrationParams: FirebaseSubscriptionModel): void {
    this.firebaseService.add(registrationParams, API_URL.firebase.unregisterUserToken)
    .pipe(takeUntil(this.destroy$))
    .subscribe({
      next: () => {
        this.localStorageService.removeItem(StorageItem.FirebaseRegistrationToken);
        this.localStorageService.removeItem(StorageItem.DeviceUUID);
      }
    });
  }
}
