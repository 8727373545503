import { Injectable } from '@angular/core';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { BaseCrudService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class NotificationService extends BaseCrudService {
  getBaseAPIPath(): string {
    return `${API_URL.services}/${API_URL.app}/${API_URL.notifications.root}`;
  }

  deleteNotification(notificationId: number, isDeleteAll: boolean, endpoint: string) {
    return this.httpClient.delete(`${this.getFullAPIUrl(endpoint)}?Id=${notificationId}&isDeleteAll=${isDeleteAll}`);
  }
}
