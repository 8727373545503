import { StudentPlanInfo } from 'src/app/pages/scheduler/pages/scheduler-wrapper/pages/schedule/models';

export interface PlanSummaries {
  planSummary: PlanSummary;
}

export interface PlanSummary {
  plan: number;
  planType: number;
  planPrice: number;
  plandetails: PlanDetails;
  categoryId: number;
  categoryName: string;
  id: number | undefined;
  instrumentId: number;
  instrumentName: string;
  documentName: string;
  fullFilePath: string;
  studentplan: StudentPlanInfo;
}

export interface PlanDetails {
  items: PlanDetailWrapper[];
}

export interface PlanDetailWrapper {
  planDetail: PlanDetail;
}

export interface PlanDetail {
  id: number;
  duration: number;
  visitsPerWeek: number;
}

export interface PlanFilters {
  planFilter: number;
  planTypeFilter: number;
  DurationFilter: number;
  VisitsPerWeekFilter: number;
}

export enum All {
  ALL = 0
}

export enum PlanType {
  BASIC = 1,
  PLUS = 2,
  PREMIUM = 3
}

export enum Duration {
  THIRTY = 30,
  FORTY_FIVE = 45,
  SIXTY = 60
}

export enum VisitsPerWeek {
  ONE_VISIT = 1,
  TWO_VISIT = 2,
  THREE_VISIT = 3,
  FOUR_VISIT = 4,
  FIVE_VISIT = 5,
  SIX_VISIT = 6
}

export enum Plan {
  RECURRING = 1,
  CUSTOM = 2
}
