import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { BaseCrudService } from 'src/app/shared/services';

@Injectable({
  providedIn: 'root'
})
export class ChatService extends BaseCrudService {
  getBaseAPIPath(): string {
    return `${API_URL.services}/${API_URL.app}/${API_URL.octopusChatAppServices.root}`;
  }

  deletePastMessage(chatType?: number, chatId?: number, endpoint?: string): Observable<any> {
    return this.httpClient.delete(`${this.getFullAPIUrl(endpoint)}?chatType=${chatType}&ChatId=${chatId}`);
  }
}
