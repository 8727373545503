import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild
} from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { takeUntil } from 'rxjs';
import { Account } from 'src/app/auth/models/user.model';
import { AuthService } from 'src/app/auth/services';
import { BaseComponent } from 'src/app/shared/components/base-component/base.component';
import { API_URL } from 'src/app/shared/constants/api-url.constants';
import { CBGetResponse } from 'src/app/shared/models';
import { FirebaseSubscriptionModel } from 'src/app/shared/models/firebase-subscription.model';
import { StorageItem } from 'src/app/shared/services/local-storage.service';
import { MessagingService } from 'src/app/shared/services/messaging.service';
import { NotificationService } from 'src/app/shared/services/notification.service';
import { SignalRService } from 'src/app/shared/services/signalr.service';
import { CommonUtils } from 'src/app/shared/utils';

@Component({
  selector: 'app-header-elements',
  templateUrl: './header-elements.component.html',
  styleUrls: ['./header-elements.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class HeaderElementsComponent extends BaseComponent implements OnInit, OnChanges {
  @Input() override currentUser!: Account | null;

  @ViewChild('logoutDialog') logoutDialog!: TemplateRef<any>;

  userInitials!: string;
  dialogRef!: MatDialogRef<any>;
  isNotificationPanelOpen = false;
  unreadNotificationCount = 0;

  constructor(
    private readonly authService: AuthService,
    private readonly cdr: ChangeDetectorRef,
    private readonly dialog: MatDialog,
    private readonly signalRService: SignalRService,
    private readonly notificationService: NotificationService,
    private readonly messagingService: MessagingService,
  ) {
    super();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.['currentUser']?.currentValue) {
      this.currentUser = changes?.['currentUser']?.currentValue;
      this.setInitials(this.currentUser?.firstName, this.currentUser?.lastName);
    }
  }

  ngOnInit(): void {
    this.getCurrentUser();
    this.getUnreadNotificationCount();
  }

  getCurrentUser(): void {
    this.authService
      .getCurrentUser$()
      .pipe(takeUntil(this.destroy$))
      .subscribe({
        next: (res: Account | null) => {
          this.currentUser = res;
          this.cdr.detectChanges();
        }
      });
  }

  setInitials(firstName?: string, lastName?: string): string {
    return !this.currentUser?.lastName
      ? CommonUtils.getInitialsUsingFullName(firstName)
      : CommonUtils.getInitials(firstName, lastName);
  }

  openLogoutConfirmationDialog(): void {
    this.dialogRef = this.dialog.open(this.logoutDialog, {
      width: '400px'
    });
  }

  closeDialog(): void {
    if (this.dialogRef) {
      this.dialogRef.close();
    }
  }

  toggleNotificationPanel(isOpen: boolean): void {
    this.isNotificationPanelOpen = isOpen;
  }

  getUnreadNotificationCount(): void {
    this.notificationService
      .getList<CBGetResponse<number>>(API_URL.notifications.getTotalCountForUnreadNotification)
      .pipe(takeUntil(this.destroy$))
      .subscribe((res: CBGetResponse<number>) => {
        this.unreadNotificationCount = res.result;
        this.cdr.detectChanges();
      });
  }

  confirmLogout(): void {
    this.closeDialog();
    this.logout();
  }

  get getFirebaseParams(): FirebaseSubscriptionModel {
    return {
      userId: this.currentUser?.userId,
      deviceId: localStorage.getItem(StorageItem.DeviceUUID)!
    }
  }

  logout(): void {
    this.signalRService.stopConnection();
    if (this.getFirebaseParams.deviceId && this.getFirebaseParams.userId) {
      this.messagingService.unRegisterToken(this.getFirebaseParams);
    }
    setTimeout(() => {
      this.authService.logOut();
    }, 0);
  }
}
