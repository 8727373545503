import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { DirectivesModule } from '../shared/directives/directives.module';
import { SharedModule } from '../shared/shared.module';
import { AuthorizedLayoutComponent } from './authorized-layout/authorized-layout.component';
import { FooterComponent } from './authorized-layout/footer/footer.component';
import { HeaderElementsComponent } from './authorized-layout/header-elements/header-elements.component';
import { HeaderComponent } from './authorized-layout/header/header.component';
import { SidebarComponent } from './authorized-layout/sidebar/sidebar.component';
import { UnauthorizedLayoutComponent } from './unauthorized-layout/unauthorized-layout.component';
import { TopnavComponent } from './authorized-layout/topnav/topnav.component';
import { MobileHeaderComponent } from './authorized-layout/mobile-header/mobile-header.component';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatInputModule } from '@angular/material/input';
import { NotificationPanelComponent } from './authorized-layout/header-elements/pages/notification-panel/notification-panel.component';
import { InfiniteScrollDirective } from 'ngx-infinite-scroll';

@NgModule({
  declarations: [
    UnauthorizedLayoutComponent,
    AuthorizedLayoutComponent,
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    HeaderElementsComponent,
    TopnavComponent,
    MobileHeaderComponent,
    NotificationPanelComponent
  ],
  imports: [
    CommonModule,
    RouterModule,
    SharedModule,
    DirectivesModule,
    MatDialogModule,
    MatButtonModule,
    CommonModule,
    MatIconModule,
    MatSidenavModule,
    MatInputModule,
    InfiniteScrollDirective
],
  providers: []
})
export class LayoutModule {}
